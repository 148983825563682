
import { nanoid } from 'nanoid';


export const codeInfomation = [
     {
          id: nanoid(),
          name: 'React Dark and Light Theme Toggle button',
          language: 'React js & tsx',
          imageUrl: 'https://create-react-app.dev/img/logo.svg',
          description: 'Discover how to effortlessly switch between light and dark modes in React with our theme toggle feature. Experience a seamless and adaptive user interface that suits your style.',
          code: `
import { DarkMode, LightMode } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'


const ThemeMode = () => {
     const defaultTheme = localStorage.getItem('theme') || 'light';

     document.documentElement.style.setProperty('color-scheme', defaultTheme);

     const [theme, setTheme] = useState(defaultTheme === 'dark');

     useEffect(() => {
          const newTheme = theme ? 'dark' : 'light';
          document.documentElement.style.setProperty('color-scheme', newTheme);
          localStorage.setItem('theme', newTheme);
     }, [theme]);

     const toggleDarkMode = () => {
          setTheme(prevTheme => !prevTheme);
     };

     return (
          <>
               <button onClick={toggleDarkMode} className='theme-btn'>
                    <span> {theme ? <DarkMode /> : <LightMode />} </span>
               </button>
          </>
     )
}

export default ThemeMode

:root {
    --body: light-dark(#fff, #000);
    --txt-white: light-dark(#fff, #000);
    --txt-black: light-dark(#000, #fff);
}
body {
    background-color: var(--body);
    color: var(--txt-black);
}`     },
     {
          id: nanoid(),
          name: 'cursor-based parallax effect in React',
          language: 'React js & tsx',
          imageUrl: 'https://create-react-app.dev/img/logo.svg',
          description: 'A section or area where the parallax effect is applied to cards or elements, making them move as the user moves their cursor over the section',
          code: `
// Import necessary hooks from React
const [offsetX, setOffsetX] = useState(0);
const [offsetY, setOffsetY] = useState(0);

// Function to handle mouse movement
const handleMouseMove = (e) => {
     const { clientX, clientY, currentTarget } = e;
     const { offsetWidth, offsetHeight } = currentTarget;

     // Calculate cursor position relative to the element
     const xPos = (clientX / offsetWidth) - 0.5; // -0.5 to 0.5
     const yPos = (clientY / offsetHeight) - 0.5; // -0.5 to 0.5

     // Update offset state based on cursor position
     setOffsetX(xPos * 30); // Adjust multiplier for intensity
     setOffsetY(yPos * 30);
};


// jsx , tsx, js for rendering
// The parent element where the mouse move is detected.
<div className="parent-section" onMouseMove={handleMouseMove}>
     <div className="animation-move" 
          style={{ transform: \`translate(\${offsetX}px, \${offsetY}px)\` }}>
     </div>
</div>

// Css and Scss
//   use background-image (animation-move)
`},
     {
          id: nanoid(),
          name: 'following dot effect a cursor circle using React',
          language: 'React js & tsx',
          imageUrl: 'https://create-react-app.dev/img/logo.svg',
          description: 'The dot\'s position is updated to follow the cursor with a slight delay to create the trailing effect. A larger circle moves with the cursor to act as the outer boundary.',
          code: `
// Import necessary hooks from React
const [dotPosition, setDotPosition] = useState({ x: 0, y: 0 });
const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });

// Function to handleMouseMove movement
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setCirclePosition({ x: event.clientX, y: event.clientY });

      // Simulate the dot lagging behind the cursor
      setTimeout(() => {
        setDotPosition({ x: event.clientX, y: event.clientY });
      }, 100); // Adjust delay to increase or decrease the lag effect
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

// jsx , tsx, js for rendering

      <div
        style={{
          position: "fixed",
          left: \`\${circlePosition.x - 25}px\`,
          top: \`\${circlePosition.y - 25}px\`,
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: "#1e1e20",
          pointerEvents: "none",
          transform: isInFooter ? 'scale(1.5)' : 'none',
          zIndex: '99999',
          transition: 'all 0.1s linear',
        }}
      />
      <div
        style={{
          position: "fixed",
          left: \`\${dotPosition.x - 5}px\`,
          top: \`\${dotPosition.y - 5}px\`,
          width:'15px',
          height:'15px',
          borderRadius: "50%",
          backgroundColor: 'var(--primary-color)',
          pointerEvents: "none",
          zIndex: '99999',
          transition: 'all 0.1s linear',
          display: isInFooter ? "none": 'block'
        }}
      />

`},
     {
          id: nanoid(),
          name: 'Header Link Active By Path True ',
          language: 'React js & tsx',
          imageUrl: 'https://create-react-app.dev/img/logo.svg',
          description: 'This code snippet demonstrates how to dynamically add an "active" class to a link based on the current URL path in a React application using React Router. The Link component checks if the current path matches the specified route (/URL). If they match, it assigns the activeLink class along with the default Link-class, providing a visual indication that the link corresponds to the current page.',
          code: `
// Import Link and useLocation
import { Link, useLocation } from 'react-router-dom'

const location = useLocation();

// rendering data
<Link to="/URL" className={location.pathname === '/URL' ? "Link-class activeLink" : ' Link-class'}> Link Name </Link>

// simple style (.activeLink)

`},
     {
          id: nanoid(),
          name: 'When the page is active, add a class to the body ',
          language: 'React js & tsx',
          imageUrl: 'https://create-react-app.dev/img/logo.svg',
          description: 'This code snippet uses the useEffect hook to add a class (add-class-body) to the <body> element when the current URL path matches a specified path (/page_url). If the URL path changes and no longer matches, the class is removed. This ensures the class is only active on a specific page.',
          code: `
// Import Link and useLocation
import { useLocation } from 'react-router-dom'

const path = "/page_url";
 
useEffect(() => {
     if (location.pathname === path) {
          document.body.classList.add('add-class-body');
     } else {
          document.body.classList.remove('add-class-body');
     }
}, [location.pathname]);

// simple style (.add-class-body)


`},
     {
          id: nanoid(),
          name: 'When the button is clicked, add a class to the body ',
          language: 'React js & tsx',
          imageUrl: 'https://create-react-app.dev/img/logo.svg',
          description: 'This code snippet uses the useEffect hook to add a class (add-class-body) to the <body> element when the current URL path matches a specified path (/page_url). If the URL path changes and no longer matches, the class is removed. This ensures the class is only active on a specific page.',
          code: `
// 1. simple button click add class
     const handleClick = () => {
          document.body.classList.add("add-class-body");
          // remove class create a function classList attribute change (.remove)
     };

// 2 Button click if isActive True than, add a class to the body
  const [isActive, setIsActive] = useState(false);

     const handleClick = () => {
          setIsActive(!isActive);
     
     
          if (!isActive) {
          document.body.classList.add('add-class-body');
          } else {
          document.body.classList.remove('add-class-body');
          }
     };
     // className={\`section \${isActive ? 'add-class-sec' : ''}\`}
     // remove class simple create a arrow function and setIsActive(false) 

// rendering data
<button onClick={() => { handleClick() }}> add class Button</button>


`},
     {
          id: nanoid(),
          name: 'Handle Click Event Based on Screen Size in React',
          language: 'React js & tsx',
          imageUrl: 'https://create-react-app.dev/img/logo.svg',
          description: 'In this code, we create a handleClick function that triggers an action only when the screen size is 768px or less. The function toggles a CSS class on the body element depending on whether a specific condition is active. This can be useful when we want to apply different behaviors or styles for mobile screens.',
          description_1: 'The key idea is to use the window.matchMedia() method, which allows us to detect the current screen size and trigger the click event based on that condition.',
          code: `
// handleClick function that works for mobile screens (max-width: 768px) "example width"

const [isActive, setIsActive] = useState(false);

const handleClick = () => {
     const isMobile = window.matchMedia('(max-width: 768px)').matches;

     if (isMobile) {
          setIsActive(!isActive);

          if (!isActive) {
               document.body.classList.add('ExampleClass');

          } else {
               document.body.classList.remove('ExampleClass');
          }
     }
};

`},


];