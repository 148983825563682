import { nanoid } from 'nanoid';
import { logo } from '../Config/Config';
// sudo npm run deploy 4

export const websites = [
    {
        id: nanoid(),
        category: 'CSS, box-shadow',
        name: 'CSS box-shadow',
        url: 'https://getcssscan.com/css-box-shadow-examples',
        imageUrl: 'https://toastlog.com/img/logos/cssscan.svg',
        description: 'Curated collection of 95 free beautiful CSS box-shadow , ready-to-use for your next projects.',
    },
    {
        id: nanoid(),
        category: 'checkbox , radio',
        name: 'CSS, radio, checkbox',
        url: 'https://getcssscan.com/css-checkboxes-examples',
        imageUrl: 'https://toastlog.com/img/logos/cssscan.svg',
        description: 'Curated collection of 95 free beautiful CSS  checkbox , radio, ready-to-use for your next projects.',
    },
    {
        id: nanoid(),
        category: 'CSS shapes',
        name: 'CSS shapes ',
        url: 'https://getcssscan.com/css-shapes',
        imageUrl: 'https://toastlog.com/img/logos/cssscan.svg',
        description: 'Curated collection of 95 free beautiful CSS shapes , ready-to-use for your next projects.',
    },
    {
        id: nanoid(),
        category: 'CSS buttons , btn ,btns',
        name: 'CSS button ',
        url: 'https://getcssscan.com/css-buttons-examples',
        imageUrl: 'https://toastlog.com/img/logos/cssscan.svg',
        description: 'Curated collection of Css buttons , ready-to-use for your next projects.',
    },
    {
        id: nanoid(),
        category: 'CSS buttons and 3d Button, btn, btns',
        name: 'CSS 3D Buttons ',
        url: 'https://csspro.com/css-3d-buttons',
        imageUrl: 'https://csspro.com/img/logo2-min.png',
        description: 'A collection of the best 3D buttons and skeuomorphic buttons made with pure CSS and HTML. Click to copy, free to use',
    },
    {
        id: nanoid(),
        category: 'Gradients, background, color , bg',
        name: 'CSS Gradients ',
        url: 'https://csspro.com/css-gradients',
        imageUrl: 'https://csspro.com/img/logo2-min.png',
        description: 'A collection of the best Gradients, Click to copy, free to use',
    },
    {
        id: nanoid(),
        category: 'SVG to png , png , svg',
        name: 'Convert SVG to png ',
        url: 'https://csspro.com/svg-to-background-image-css',
        imageUrl: 'https://csspro.com/img/logo2-min.png',
        description: 'A Convert SVG to background-image made with pure CSS and HTML',
    },
    {
        id: nanoid(),
        category: 'Compress, image',
        name: 'Compress IMAGE ',
        url: 'https://www.iloveimg.com/compress-image',
        imageUrl: 'https://www.iloveimg.com/img/iloveimg.svg',
        description: 'Compress JPG, PNG, SVG or GIF with the best quality and compression',
    },
    {
        id: nanoid(),
        category: 'Remove background , remove-bg ',
        name: 'Remove background ',
        url: 'https://www.iloveimg.com/remove-background',
        imageUrl: 'https://www.iloveimg.com/img/iloveimg.svg',
        description: 'Cut out image background. Remove the background of your JPG and PNG',
    },
    {
        id: nanoid(),
        category: 'Remove background , remove-bg ',
        name: 'Remove background ',
        url: 'https://www.remove.bg/',
        imageUrl: 'https://www.remove.bg/favicon-32x32.png?v=fc0bfce6e1310f1539afec9729716721',
        description: 'Remove Image Background',
    },
    {
        id: nanoid(),
        category: 'Oh-My-Zsh Git ',
        name: 'Git & Github shortcuts',
        url: 'https://kapeli.com/cheat_sheets/Oh-My-Zsh_Git.docset/Contents/Resources/Documents/index',
        imageUrl: 'https://github.com/fluidicon.png',
        description: 'Git , GitHub is where people build software',
    },
    {
        id: nanoid(),
        category: 'React Spinners and Loader',
        name: 'React Spinners, Loader',
        url: 'https://www.davidhu.io/react-spinners/',
        imageUrl: 'https://www.davidhu.io/favicon-32x32.png',
        description: 'React Spinners and loader more style',
    },
    {
        id: nanoid(),
        category: 'generates logo ',
        name: 'generates Logo and banner',
        url: 'https://ideogram.ai/t/explore',
        imageUrl: 'https://ideogram.ai/favicon.png',
        description: 'generates realistic images, posters, logos and more',
    },
    {
        id: nanoid(),
        category: 'Generate unique SVG design ',
        name: 'Generate unique SVG design',
        url: 'https://app.haikei.app/',
        imageUrl: 'https://images.saasworthy.com/tr:w-160,h-0,c-at_max,e-sharpen-1/haikei_29585_logo_1645705513_ltv04.jpg',
        description: 'Generate unique SVG design assets, background and more',
    },
    {
        id: nanoid(),
        category: 'CSS clip-paths ',
        name: 'CSS clip-paths',
        url: 'https://www.htmlcssbuttongenerator.com/CSS-clip-path-community.html',
        imageUrl: 'https://htmlcssbuttongenerator.com/image/newlogo.png',
        description: 'CSS clip-paths made by the community',
    },
    {
        id: nanoid(),
        category: 'Fancy border radius ',
        name: 'CSS Fancy border radius',
        url: 'https://www.htmlcssbuttongenerator.com/border-radius-generator.php',
        imageUrl: 'https://htmlcssbuttongenerator.com/image/newlogo.png',
        description: 'Custom border radius best quality and compression',
    },
    {
        id: nanoid(),
        category: 'Showcase of the best 1160 Animation Websites ',
        name: 'Showcase of the best 1160 Animation Websites',
        url: 'https://www.landing.love/',
        imageUrl: 'https://www.landing.love/img/favicon.png',
        description: 'Custom border radius best quality and compression',
    },
    {
        id: nanoid(),
        category: 'Loaders and npm ',
        name: 'Loaders and npm',
        url: 'https://uiball.com/ldrs/',
        imageUrl: 'https://uiball.com/apple-touch-icon.png',
        description: 'Free, open-source loaders and spinners for your next project.',
    },
    {
        id: nanoid(),
        category: 'Copy paste the most trending components ',
        name: 'Make your websites look , components ',
        url: 'https://ui.aceternity.com/components',
        imageUrl: 'https://ui.aceternity.com/apple-icon.png?2deb28af3ac52d4d',
        description: 'Copy paste the most trending components and use them in your websites without having to worry about styling and animations.',
    },
    {
        id: nanoid(),
        category: 'CSS only loaders made using a single element ',
        name: 'CSS only loaders made using a single element',
        url: 'https://uiball.com/ldrs/',
        imageUrl: 'https://css-loaders.com/fav.png',
        description: 'The Biggest Collection of Loading Animations.',
    },
    {
        id: nanoid(),
        category: 'UI Library made with CSS, HTML, uiverse ',
        name: 'UI Library made with CSS, HTML, uiverse',
        url: 'https://uiverse.io/',
        imageUrl: 'https://uiverse.io/android-chrome-512x512.png',
        description: 'Library of free and customizable UI elements made with CSS or Tailwind',
    },
    {
        id: nanoid(),
        category: 'ui aceternity react ui ',
        name: 'ui aceternity and React base ui ',
        url: 'https://ui.aceternity.com/',
        imageUrl: 'https://ui.aceternity.com/apple-icon.png?2deb28af3ac52d4d',
        description: 'Make your websites look 10x modern',
    },
    {
        id: nanoid(),
        category: 'cleanup pictures , remove object',
        name: 'cleanup pictures , remove object ',
        url: 'https://cleanup.pictures/',
        imageUrl: 'https://cleanup.pictures/logo192.png',
        description: 'Create a clean background for a product picture',
    },
    {
        id: nanoid(),
        category: 'web design background png  , gradients png',
        name: 'background png and , gradients png , bg-png',
        url: 'https://www.magicpattern.design/tools/mesh-gradients',
        imageUrl: 'https://www.magicpattern.design/static/favicons/apple-touch-icon.png',
        description: 'your web design projects, graphic design posters',
    },
    {
        id: nanoid(),
        category: 'Background tool ',
        name: 'Background tool , bg,',
        url: 'https://www.magicpattern.design/tools',
        imageUrl: 'https://www.magicpattern.design/static/favicons/apple-touch-icon.png',
        description: 'A collection of tools to enhance your design and code',
    },
    {
        id: nanoid(),
        category: 'gradients, patterns, shapes, textures  fffuel',
        name: 'gradients, patterns, shapes, textures all in one fffuel',
        url: 'https://www.fffuel.co',
        imageUrl: 'https://www.fffuel.co/images/favicon/favicon.svg',
        description: 'A fun collection of free SVG generators for gradients, patterns, shapes, textures &amp; cool backgrounds. Plus, some more color tools for web designers',
    },
    {
        id: nanoid(),
        category: 'Inspiration Design Website',
        name: 'Inspiration Design Website',
        url: 'https://www.dark.design/',
        imageUrl: 'https://framerusercontent.com/images/z0D3A9SXHg6iTI90pfFgZ2ds03k.png',
        description: 'The best hand-picked dark themed websites on the internet. From designers for designers. Curated with love.',
    },
    {
        id: nanoid(),
        category: 'SVG to Code  ',
        name: 'Convert SVG image to code ',
        url: 'https://thedchere.github.io/tools/#svg-to-code',
        imageUrl: logo,
        description: 'Online tool to convert SVG image to code',
    },
    {
        id: nanoid(),
        category: 'SVG circle progress generator',
        name: 'SVG circle progress generator for code',
        url: 'https://thedchere.github.io/tools/#svg-circle-progress',
        imageUrl: logo,
        description: 'SVG circle progress generator for code',
    },

    {
        id: nanoid(),
        category: 'Responsive Tester',
        name: 'Responsive Tester',
        url: 'https://thedchere.github.io/tools/#responsive-tester',
        imageUrl: logo,
        description: 'Only for Responsive Tester',
    },
    {
        id: nanoid(),
        category: 'Unblast - Mockups Fonts Icons Templates ',
        name: 'Unblast - Mockups Fonts Icons Templates Graphics3D Models Color',
        url: 'https://unblast.com',
        imageUrl: 'https://unblast.com/wp-content/themes/unblast-beta/images/unblast.svg',
        description: 'free design resources made by the world&#039;s best designers. Download quality mockups, fonts, templates, and graphics."',
    },
    {
        id: nanoid(),
        category: 'illustrations Free Svg, Png',
        name: 'illustrations Free Svg, Png',
        url: 'https://illlustrations.co/',
        imageUrl: 'https://illlustrations.co/icons/icon-144x144.png?v=1d0394bf9fc267e40ccc6d562175855a',
        description: 'Designed 100 awesome illustrations during 100 days of illustration challenge',
    },
    {
        id: nanoid(),
        category: '3D Illustrations for UI design.',
        name: '3D Illustrations forUI design.',
        url: 'https://shapefest.com/',
        imageUrl: 'https://framerusercontent.com/images/SRrYd5zBzBzS5v5dFnqVgjUbQ.png',
        description: 'a massive library of free 3D illustrations created by Joseph Angelo Todaro.',
    },
    {
        id: nanoid(),
        category: 'Loader , Loading',
        name: 'Whirl Loader ',
        url: 'https://whirl.netlify.app/',
        imageUrl: 'https://whirl.netlify.app/favicon.ico',
        description: 'Whirl: CSS loading animations with minimal effort!.',
    },
    {
        id: nanoid(),
        category: 'Dummy JSON API Freeapi',
        name: 'DummyJSON - Free Fake REST API for Placeholder JSON Data ',
        url: 'https://dummyjson.com/',
        imageUrl: 'https://assets.dummyjson.com/public/fav.png',
        description: 'DummyJSON provides a free fake REST API with placeholder JSON data for development, testing, and prototyping. Access realistic data quickly for your projects.',
    },
];
